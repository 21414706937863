.header-menu-mobile {

    .btn-custom {
        color: #B5B5C3;
        background-color: rgba(63, 66, 84, 0.35) !important;
    }

    .breadcrumb-item {
        &.active {
            color: white;
        }
    }

    .report-item {
        background-color: var(--kt-app-bg-color);
    }
}