body[data-kt-app-sidebar-minimize=on] {

    .menu-minimize-trigger {
        position: absolute;
        width: 100%;
        height: 100%;
    }


}

body:not([data-kt-app-sidebar-minimize]) {
    .menu-minimize-trigger {
        display: none;
    }

}

.app-sidebar {

    .reports-dropdown {
        transform: translate(265px, 55px) !important;
    }

    .app-sidebar-wrapper {
        overflow-y: auto;
    }

    .custom-title-color:hover {
        color: #a4cd39 !important;
    }
}