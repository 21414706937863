@import "../../../_metronic/assets/sass/core/components/variables";

.search {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #393945;

    .app-select-search-container {
        width: 100%;

        .app-select-search__control {
            box-shadow: none;
            background-color: unset;
            border: none;
            color: white;

            .app-select-search__single-value {
                color: white;
            }
        }
    }
}