.drop-down {

    .dropdown-menu {
        z-index: 100 !important;
        border: 1px solid #DEE2E6;
        box-sizing: border-box;
        box-shadow: 0px 4px 16px rgba(65, 65, 65, 0.15);
        border-radius: 4px;
        padding: 0.75rem 0;

        // > a.dropdown__item-danger {
        //     &:active {
        //         background-color: $color-brand-red;
        //         color: $color-font-white;
        //     }
        // }
    }

    // .dropdown-item {
    //     color: $color-font-bluish-gray;
    //     padding: 0.5rem 1rem;
    //     font-size: 14px;

    //     &:active {
    //         background-color: $color-brand-blue;
    //         color: $color-font-white;

    //         > p {
    //             color: $color-font-white;
    //         }
    //     }
    // }
}