[data-theme="dark"] {
    .css-b62m3t-container {
        input {
            color: white !important;
        }
    }

    .css-26l3qy-menu {
        background-color: #151521;

        .css-1n7v3ny-option {
            color: #151521;
        }
    }
}