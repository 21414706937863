.app-header {

    .h-reports-dropdown {
        transform: translate(-50%, -47%) !important;
        top: 50% !important;
        left: 50% !important;
    }

    &-mobile-drawer {
        margin-top: 60px;
        background-color: #1e1e2d;

        @media (min-width: 992px) {
            background-color: white;
            margin-top: 0;
        }
    }

    .unset-bg {
        background-color: unset !important;
    }

    .bare-button {
        /* Resetting default styles */
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        cursor: pointer;
    }

    .text-button {
        background: none;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        /* Additional styles to make it look like plain text */
        color: inherit;
        text-decoration: underline;
    }
}