.placeholder-wave {
    min-width: 100px;
    width: 100%;
    color: lightgrey;
    margin: auto;

    .placeholder {
        &.size {
            &-xs {
                margin: 10px;
            }

            &-md {
                margin: 15px;
            }

            &-lg {
                margin: 20px;
            }
        }
    }

}