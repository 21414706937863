@import '../../../_metronic/assets/sass/core/components/variables';

.account-menu {
    .menu-link:hover {
        color: $primary-active !important;
    }

    .menu-link.-primary:hover {
        color: var(--kt-primary) !important;   
    }

    .menu-link.-pitcher-partners:hover {
        color: var(--kt-pitcher-partners) !important;
    }

    .menu-link.-cgnw:hover {
        color: var(--kt-cgnw) !important;
    }
}