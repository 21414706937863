.typography {
    line-height: 1.5;
    margin-bottom: 0;

    &-icon {
        &.size {
            &-sm {
                height: 16px;
            }

            &-md {
                height: 24px;
            }

            &-lg {
                height: 40px;
            }
        }
    }
}