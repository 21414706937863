.tableau-report {
    .tableau-frame {

        &-vertical {
            iframe {
                width: 100% !important;
                height: calc(100vh - 80px) !important;
            }

            .filter-height {
                height: calc(100vh - 80px) !important;
            }
        }

        &-horizontal {
            iframe {
                width: 100% !important;
                height: calc(100vh - 130px) !important;
            }

            .filter-height {
                height: calc(100vh - 130px) !important;
            }
        }

        &-mobile {
            iframe {
                height: unset;
            }

            .filter-height {
                height: unset;
            }
        }

        &-fullscreen {
            height: 100vh !important;

            iframe {
                height: 100vh !important;
            }

            .filter-height {
                height: 100vh !important;
            }
        }
    }
}