body[data-kt-app-sidebar-minimize=on] {
    .app-sidebar {
        .tablet-minimize {
            display: none;
        }

        .app-sidebar-footer {
            &-logo {
                &-default {
                    display: none !important;
                }

                &-minimize {
                    display: inline-block;
                }
            }
        }

        &:hover {

            .hide-name,
            .hide-switch,
            .app-sidebar-footer-logo-default {
                display: block !important;
            }

            .app-sidebar-footer-logo-minimize {
                display: none !important;

            }
        }
    }
}

body:not([data-kt-app-sidebar-minimize]) {
    .app-sidebar {

        .hide-name,
        .hide-switch {
            display: block !important;
        }
    }

    .app-sidebar-footer-logo-minimize {
        display: none !important;
    }
}

.app-sidebar-wrapper {
    overflow-y: auto;
}

.menu-title {
    color: #9D9DA6;
}