@import '../../../_metronic/assets/sass/core/components/_variables.scss';

.reports-nav {
    height: 100%;
    display: flex;

    .report-item {
        padding: 10px;
        border-radius: 6px;
    }

    .nav-list {
        min-width: 340px;
        height: 100%;
        background-color: $body-bg-dark;
        padding: 40px;

        .group-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:hover {
                opacity: 1 !important;
            }
        }

        .group-name:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .nav-content {
        padding: 40px;
        overflow: auto;
        width: 100%;
        background-color: var(--kt-app-bg-color);

        .placeholder-image {
            width: 250px;
            height: 142px;

            img {
                display: block;
                max-width: 250px;
                max-height: 142px;
                width: auto;
                height: auto;
            }
        }

        .report-description {
            white-space: break-spaces;
        }
    }
}