.app-datepicker-container {
    position: relative; /* Positioning for the icon */
}
  
.calendar-icon {
    position: absolute; /* Absolute positioning */
    left: 10px; /* Adjust left position */
    top: 50%; /* Vertically center the icon */
    transform: translateY(-50%); /* Adjust vertical alignment */
}
  
.custom-datepicker {
    padding-left: 30px; /* Add padding to accommodate the icon */
    border-radius: 5px; /* Rounded corners */
    border: 1px solid #ccc; /* Border */
    font-size: 14px; /* Font size */
    width: 150px; /* Adjust width as needed */
}

.react-datepicker {
    &-wrapper {
        display: inline !important;
    }

    &__input-container {
        input {
            background-color: hsl(0, 0%, 100%);
            border-color: hsl(0, 0%, 80%);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            padding: 0 10px;
            font-size: 14px;
            min-height: 38px;
            outline: none;
            color: hsl(0, 0%, 50%);

            &:focus-visible {
                outline: 0;
            }
        }
    }

    &__calendar-icon {
        top: 7px
    }
}