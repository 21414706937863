.spreadsheet-grid-aircraft-airline-reference {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 32.5px 40px 32.5px;

    .ag-popup {
        height: 0 !important;
    }

    .grid-control {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        &-layout {
            gap: 5px;
            display: flex;
        }
    }

    .grid-container {
        width: 100%;
        overflow-x: auto;
    }

    .grid-pagination {
        display: flex;
        align-items: center;

        &-control {
            display: flex;
            gap: 5px;
        }
    }

    .ag-theme-alpine {
        width: 100%;

        &.vertical {
            height: calc(100vh - 205px);
        }

        &.horizontal {
            height: calc(100vh - 275px);
        }
    }

    #page-size {
        height: 25px;
    }
}