@import "../../../../_metronic/assets/sass/core/components/variables";

.tableau-filter {

    .slider {
        margin-top: 8px;
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.75;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 12px;
            height: 25px;
            background: $primary;
            cursor: pointer;
            border-radius: 3px;
        }

        &::-moz-range-thumb {
            width: 12px;
            height: 25px;
            background: $primary;
            cursor: pointer;
        }

        &:hover {
            opacity: 1;
        }
    }

    .app-select-filter__control {
        color: black;

        .app-select-filter__input {
            color: black !important;
        }
    }
}