.time-input {

    /* Set the border and padding to default values */
    border: initial;

    /* Reset the font styles to inherit from the parent */
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    /* Remove any background color */
    background-color: transparent;

    /* Remove outline on focus */
    outline: none;
    width: 100%;
    padding: 0 5px;
}