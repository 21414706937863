.tabs {

    .menu-link.active {
        background-color: var(--kt-menu-link-bg-color-active) !important;
    }

    .menu-link.-primary.active {
        span {
            color: var(--kt-primary) !important;
        }
    }

    .menu-link.-primary:hover {
        span {
            color: var(--kt-primary) !important;
        }
    }

    .menu-link.-pitcher-partners.active {
        span {
            color: var(--kt-pitcher-partners) !important;
        }
    }

    .menu-link.-pitcher-partners:hover {
        span {
            color: var(--kt-pitcher-partners) !important;
        }
    }

    .menu-link.-cgnw.active {
        span {
            color: var(--kt-cgnw) !important;
        }
    }

    .menu-link.-cgnw:hover {
        span {
            color: var(--kt-cgnw) !important;
        }
    }
}